/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import fetch from 'cross-fetch';
import { coreInit, fetchOk } from './request_utils';
import Debug from 'debug';

let debug = Debug('settings_requests');


export const getSettings = () => {
	debug('getSettings, called.');

	let init = {
		...coreInit,
		method:			'GET'
	};

	debug('getSettings, init is: ' + JSON.stringify(init));
	return fetchOk('/setting', init)
		.then((response) => {debug('getSettings, response ok.');return response;})
		.catch((error) => {debug('getSettings, fetch, caught err.'); throw error;});

};

export const addParam = (param) => {
	debug('addParam, called.');

	let init = {
		credentials:	'same-origin',
		method:			'POST'
	};

	debug('addParam, init is: ' + JSON.stringify(init));
	return fetchOk('/setting/param/' + param, init)
		.then((response) => {debug('addParam, response ok.');return response;})
		.catch((error) => {debug('addParam, fetch, caught err.'); throw error;});

};

export const getParam = (id) => {
	debug('getParam, called.');

	let init = {
		credentials:	'same-origin',
		method:			'GET'
	};

	debug('getParam, init is: ' + JSON.stringify(init));
	return fetchOk('/setting/param/' + id, init)
		.then((response) => {debug('getParam, response ok.');return response;})
		.catch((error) => {debug('getParam, fetch, caught err.'); throw error;});

};

export const updateParam = (id, param) => {
	debug('updateParam, called.');
	const formData = new FormData();
	formData.append('param', param);

	let init = {
		credentials:	'same-origin',
		method:			'PUT',
		body:				formData
	};

	debug('updateParam, init is: ' + JSON.stringify(init));
	return fetchOk('/setting/param/' + id, init)
		.then((response) => {debug('updateParam, response ok.');return response;})
		.catch((error) => {debug('updateParam, fetch, caught err.'); throw error;});

};

export const deleteParam = (id) => {
	debug('deleteParam, called.');

	let init = {
		credentials:	'same-origin',
		method:			'DELETE'
	};

	debug('deleteParam, init is: ' + JSON.stringify(init));
	return fetchOk('/setting/param/' + id, init)
		.then((response) => {debug('deleteParam, response ok.');return response;})
		.catch((error) => {debug('deleteParam, fetch, caught err.'); throw error;});

};

export const updateDepth = (depth) => {
	debug('updateDepth, called.');

	let init = {
		credentials:	'same-origin',
		method:			'PUT'
	};

	debug('updateDepth, init is: ' + JSON.stringify(init));
	return fetchOk('/setting/depth/' + depth, init)
		.then((response) => {debug('updateDepth, response ok.');return response;})
		.catch((error) => {debug('updateDepth, fetch, caught err.'); throw error;});

};
