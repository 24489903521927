/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import AddCompanyView from './AddCompanyView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('AddCompany');

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		createCompany: (name, logo, description, url) => {dispatch(actions.createCompany(name, logo, description, url));}
	};
};

const AddCompany = connect(null, mapDispatchToProps)(AddCompanyView);

export default AddCompany;
