/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import * as index from '../index';
import * as actions from './action_types';
import * as requests from '../requests';

export const getChats = (companyId) => ({ type: actions.GET_CHATS, promise: requests.getChats(companyId) });
export const getChat = (id) => ({ type: actions.GET_CHAT, promise: requests.getChat(id) });
export const startChat = (companyId) => ({ type: actions.START_CHAT, promise: requests.startChat(companyId)});
export const ask = (id, prompt) => ({type: actions.ASK, promise: requests.ask(id, prompt)});
export const endChat = (id) => ({ type: actions.END_CHAT, promise: requests.endChat(id)});
export const deleteChat = (id, companyId) => ({ type: actions.DELETE_CHAT, promise: requests.deleteChat(id), meta: {onSuccess: (chat) => index.store.dispatch(getChats(companyId))}});
