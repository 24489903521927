/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../shared/ErrorBoundary';
import { hidden } from '../../shared/constants';
import { sizeOk } from '../../shared/functions';
import Debug from 'debug';

let debug = Debug('AddCompanyView');

export class AddCompanyView extends Component {

	handleAdd() {
		debug('handleUpdate, called.');
        document.querySelector('#too-large').hidden = true;

        if (sizeOk(document.querySelector('#logo').files[0])) {
    		this.props.createCompany(
    			document.querySelector('#name').value,
                document.querySelector('#logo').files[0],
                document.querySelector('#description').value,
    			document.querySelector('#url').value
    		);

    		document.querySelector('#name').value = '';
            document.querySelector('#logo').files = null;
            document.querySelector('#description').value = '';
    		document.querySelector('#url').value = '';
        } else {
            document.querySelector('#too-large').hidden = false;
        }
	}


	render () {
		debug('render, called.');

		return <div className="modal fade" id="add-company-modal" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="feed-title" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="company-title">Add Company Dialog</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<div className="mb-3">
							<label htmlFor="name" className="form-label">Name</label>
							<input className="form-control" id="name" aria-label="company name" placeholder="Name of the company. (required)" required></input>
						</div>
                        <div className="mb-3">
                            <label htmlFor="logo" className="form-label">Logo</label>
                            <div className="col form-group input-block">
                                <div className="custom-file">
                                    <input id="logo" type="file" tabIndex={15} className="form-control custom-file-input" accept="image/png, image/jpeg, image/gif"/>
                                </div>
                                <p className="small text-muted">Images should be png, gif, jpeg or jpeg files under 10Mb.</p>
                                <p className="small text-info" id="too-large" hidden>The selected image is too large.</p>
                            </div>
                        </div>
                        <div className="mb-3">
    						<label htmlFor="description" className="form-label">Description</label>
    						<textarea className="form-control" id="description" rows="3"  aria-label="description" placeholder="A brief description of the company and what they do."></textarea>
    					</div>
						<div className="mb-3">
							<label htmlFor="url" className="form-label">Website</label>
							<input type="url" className="form-control" id="url" aria-label="website" placeholder="The companies website. (required)" required></input>
							<p className="small text-muted">Must begin http:// or https://</p>
						</div>

					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-outline-primary" onClick={this.handleAdd.bind(this)} data-bs-dismiss="modal">Add</button>
						<button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>;
	}
}


AddCompanyView.propTypes = {
	createCompany:		PropTypes.func
};


export default AddCompanyView;
