/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import DetailTabView from './DetailTabView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('DetailTab');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		isworking:	state.company.isworking,
        company:    state.company.company
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
        updateCompany: (id, name, logo, description, url) => {dispatch(actions.updateCompany(id, name, logo, description, url));}
	};
};

const DetailTab = connect(mapStateToProps, mapDispatchToProps)(DetailTabView);

export default DetailTab;
