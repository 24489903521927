/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal } from 'bootstrap';
import Masonry from 'masonry-layout';
import ErrorBoundary from '../shared/ErrorBoundary';
import CompanyCard from './cards/CompanyCard';
import AddCompany from './modals/AddCompany';
import Chat from './modals/Chat';
import Main from '../Main';
import Debug from 'debug';


let debug = Debug('HomeView');




class HomeView extends Component {

	shouldComponentUpdate() {
		debug('shouldComponentUpdate, called.');
		return this.props.isworking;
	}

	componentDidMount() {
		debug('componentDidMount, called.');
		this.props.getCompanies();
	}

	componentDidUpdate() {
		debug('componentDidUpdate, called.');

		if (this.masonry) {
			this.masonry.layout();
		} else {
			this.masonry = new Masonry('.companies-grid', {percentPosition: true});
			this.masonry.layout();
		}
	}

	renderCompanies() {
		debug('renderCompanies, called.');
		if (this.props.companies.length === 0) {
			return (<h2 className="text-muted text-center mb-3">There are no start-ups in the deal flow.</h2>);
		} else {
			return (this.props.companies.map((company, index) => (<ErrorBoundary key={company._id}><CompanyCard company={company} index={index}/></ErrorBoundary>)));
		}
	}

    addCompany() {
        debug('addCompany, called.');
		const companyModal = new Modal('#add-company-modal');
		companyModal.show();
    }

  	render () {
    	debug('render, called.');
		//if (this.props.articles) {
		return 	<Main>
            <div className="container-fluid main-bar">
                <div className="row  align-items-center">
                    <div className="col">
                        <h4>Deal-Flow</h4>
                    </div>
                    <div className="col text-end">
                        <button className="btn btn-primary" type="button" onClick={this.addCompany.bind(this)}>Add company</button>
                    </div>
                </div>
                <hr className="my-3"/>
            </div>


			<div className="container-fluid">
				<div className="companies-grid row">
					{this.renderCompanies()}
				</div>
			</div>
            <ErrorBoundary><AddCompany /></ErrorBoundary>
			<ErrorBoundary><Chat /></ErrorBoundary>
		</Main>;
	}
};


HomeView.propTypes = {
	isworking:		PropTypes.bool,
	getCompany: 	PropTypes.func,
	getCompanies: 	PropTypes.func,
	companies:		PropTypes.array
};


export default HomeView;
