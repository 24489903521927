/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import Debug from 'debug';

let debug = Debug('ChatViewer');

export default class ChatViewer extends Component {

	chatContent(chat) {
		debug('chatContent, called.');
		if (chat) {
			if (chat.chat) {
				return chat.chat.map(interaction => <div className="row" key={interaction._id}>
	                <div className="col-12">
	                    <div className="prompt">{interaction.prompt}</div>
	                </div>
	                <div className="col-12">
	                    <a className="response" data-bs-toggle="collapse" href={'#src' + interaction._id} aria-expanded="false" aria-controls="show sources">{interaction.response}</a>
	                </div>
	                <div className="col-12 collapse" id={'src' + interaction._id}>
	                    <i className="sources small">Sources used: {interaction.sources.join(', ')}</i>
	                </div>
	            </div>
	            );
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

  	render () {
    	debug('render, called.');

		return <div className="card">
			<div className="card-body overflow-auto chat">
				{this.chatContent(this.props.chat)}
			</div>
		</div>;
	}
};
