/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import CompanyView from './CompanyView';
import * as actions from '../../actions';
import Debug from 'debug';

let debug = Debug('Company');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		user:		state.auth.user
	};
};


const Company = connect(mapStateToProps, null)(CompanyView);

export default Company;
