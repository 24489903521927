/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import DetailDocsView from './DetailDocsView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('DetailDocs');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		isworking:	state.company.isworking,
        company:    state.company.company,
		user:		state.auth.user
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
        addDoc: (id, doc) => {dispatch(actions.addDoc(id, doc));},
        deleteDoc: (id, doc) => {dispatch(actions.deleteDoc(id, doc));},
        downloadWeb: (id, givenName, email) => {dispatch(actions.downloadWeb(id, givenName, email));}
	};
};

const DetailDocs = connect(mapStateToProps, mapDispatchToProps)(DetailDocsView);

export default DetailDocs;
