/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import fetch from 'cross-fetch';
import { coreInit, fetchOk, appendObjectArrayToFormData } from './request_utils';
import Debug from 'debug';

let debug = Debug('company_requests');

export const createCompany = (name, logo, description, url) => {
	debug('createCompany, called.');
	debug('createCompany, adding: ' + name, logo, description, url);
    const formData = new FormData();
	formData.append('name', name);
	formData.append('logo', logo);
	formData.append('description', description);
	formData.append('url', url);

	let init = {
		credentials:	'same-origin',
		method:			'POST',
		body:			formData
	};

	debug('createCompany, init is: ' + JSON.stringify(init));
	return fetchOk('/company', init)
		.then((response) => {debug('createCompany, response ok.');return response;})
		.catch((error) => {debug('createCompany, fetch, caught err.'); throw error;});

};

export const getCompanies = () => {
	debug('getCompanies, called.');

	let init = {
		...coreInit,
		method:			'GET'
	};

	debug('getCompanies, init is: ' + JSON.stringify(init));
	return fetchOk('/companies', init)
		.then((response) => {debug('getCompanys, response ok.');return response;})
		.catch((error) => {debug('getCompanys, fetch, caught err.'); throw error;});

};

export const getCompany = (id) => {
	debug('getCompany, called.');

	let init = {
		...coreInit,
		method:			'GET'
	};

	debug('getCompany, init is: ' + JSON.stringify(init));
	return fetchOk('/company/' + id, init)
		.then((response) => {debug('getCompany, response ok.');return response;})
		.catch((error) => {debug('getCompany, fetch, caught err.'); throw error;});

};

export const updateCompany = (id, name, logo, description, url) => {
	debug('updateCompany, called.');
    debug('updateCompany, adding: ' + name, logo, description, url);
    const formData = new FormData();
	formData.append('name', name);
	formData.append('logo', logo);
	formData.append('description', description);
	formData.append('url', url);

	let init = {
		credentials:	'same-origin',
		method:			'PUT',
		body:			formData
	};

	debug('updateCompany, init is: ' + JSON.stringify(init));
	return fetchOk('/company/' + id, init)
		.then((response) => {debug('updateCompany, response ok.');return response;})
		.catch((error) => {debug('updateCompany, fetch, caught err.'); throw error;});

};

export const deleteCompany = (id) => {
	debug('deleteCompany, called.');

	let init = {
		...coreInit,
		method:			'DELETE',
		body:			JSON.stringify({'id': id})
	};

	debug('deleteCompany, init is: ' + JSON.stringify(init));
	return fetchOk('/company/' + id, init)
		.then((response) => {debug('deleteCompany, response ok.');return response;})
		.catch((error) => {debug('deleteCompany, fetch, caught err.'); throw error;});

};

export const addDoc = (id, doc) => {
    debug('addDoc, called.');
    const formData = new FormData();
	formData.append('doc', doc);


	let init = {
		credentials:	'same-origin',
		method:			'POST',
		body:			formData
	};

	debug('addDoc, init is: ' + JSON.stringify(init));
	return fetchOk('/company/' + id + '/doc/', init)
		.then((response) => {debug('addDoc, response ok.');return response;})
		.catch((error) => {debug('addDoc, fetch, caught err.'); throw error;});
};

export const deleteDoc = (id, doc) => {
    debug('deleteDoc, called.');

	let init = {
		credentials:	'same-origin',
		method:			'DELETE'
	};

	debug('deleteDoc, init is: ' + JSON.stringify(init));
	return fetchOk('/company/' + id + '/doc/' + doc, init)
		.then((response) => {debug('deleteDoc, response ok.');return response;})
		.catch((error) => {debug('deleteDoc, fetch, caught err.'); throw error;});
};

export const downloadWeb = (id, givenName, email) => {
    debug('downloadWeb, called.');
	const formData = new FormData();
	formData.append('givenName', givenName);
	formData.append('email', email);

	let init = {
		credentials:	'same-origin',
		method:			'POST',
		body:			formData
	};

	debug('downloadWeb, init is: ' + JSON.stringify(init));
	return fetchOk('/company/' + id + '/web/', init)
		.then((response) => {debug('downloadWeb, response ok.');return response;})
		.catch((error) => {debug('downloadWeb, fetch, caught err.'); throw error;});
};

export const storeData = (id, givenName, email) => {
    debug('storeData, called.');
	const formData = new FormData();
	formData.append('givenName', givenName);
	formData.append('email', email);

	let init = {
		credentials:	'same-origin',
		method:			'POST',
		body:			formData
	};

	debug('storeData, init is: ' + JSON.stringify(init));
	return fetchOk('/company/' + id + '/store/', init)
		.then((response) => {debug('storeData, response ok.');return response;})
		.catch((error) => {debug('storeData, fetch, caught err.'); throw error;});
};
