/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import './scss/saicvc.scss';
import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { createStore, applyMiddleware } from 'redux';
import { middleware as pack } from 'redux-pack';
import App from './components/App';
import reducer from './reducer';
import 'core-js/stable';
import 'bootstrap';
//import moment from 'moment';



// Now set up the local debug
import Debug from 'debug';

let debug = Debug('index');

if (document.querySelector('#app-script').getAttribute('data-env') === 'development') {
	Debug.enable('*');
	debug('debug enabled');
} else {
	Debug.disable();
}

debug('creating redux store');
export const store = createStore(reducer, applyMiddleware(pack));

//debug('set up calendar localizer');
//export const localizer = momentLocalizer(moment);
const container = document.getElementById('app');
const root = createRoot(container);

// Now set up the routing
debug('Rendering provider');
root.render(
	<Provider store={store}>
		<App/>
	</Provider>
);
