/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import { combineReducers } from 'redux';
import auth_reducer from './auth_reducer';
import profile_reducer from './profile_reducer';
import company_reducer from './company_reducer';
import chat_reducer from './chat_reducer';
import settings_reducer from './settings_reducer';

import Debug from 'debug';

let debug = Debug('reducer');

const reducer = combineReducers({
	auth: 		auth_reducer,
	profile: 	profile_reducer,
	company: 	company_reducer,
	chat:		chat_reducer,
	settings:	settings_reducer
});

export default reducer;
