/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../shared/ErrorBoundary';
import Debug from 'debug';

let debug = Debug('AddParamView');

export class AddParamView extends Component {

	handleAdd() {
		debug('handleUpdate, called.');

		this.props.addParam(
			document.querySelector('#add-name').value
		);

		document.querySelector('#add-name').value = '';
	}


	render () {
		debug('render, called.');

		return <div className="modal fade" id="add-param-modal" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="param-title" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="param-title">Add Parameter Dialog</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div className="modal-body">
						<div className="mb-3">
							<label htmlFor="add-name" className="form-label">Parameter name</label>
							<input className="form-control" id="add-name" aria-label="feed name" placeholder="Only the parameter name, do not add & or =." required></input>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-outline-primary" onClick={this.handleAdd.bind(this)} data-bs-dismiss="modal">Add</button>
						<button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>;
	}
}


AddParamView.propTypes = {
	settings:			PropTypes.object,
	updateSettings:		PropTypes.func
};


export default AddParamView;
