/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from './shared/ErrorBoundary';
import MainMenu from './MainMenu';
import { Toast } from 'bootstrap';
import Footer from './Footer';
import AlertToast from './shared/AlertToast';
import MessageToast from './shared/MessageToast';
import Debug from 'debug';

let debug = Debug('MainView');

class MainView extends Component {
	err = '';
	alertToast = null;

	componentDidMount() {
		debug('componentDidUpdate, getting toast.');
		// Get the session User
		this.props.getUser();

		// Set up the alert toast for the page
		let alertToast = document.getElementById('alert-toast');
		alertToast.addEventListener('hidden.bs.toast', function () {
			this.err = '';
		});

		this.alertToast = Toast.getOrCreateInstance(alertToast);
	}

	componentDidUpdate() {
		debug('componentDidUpdate, called.');
		// Check if there are errors to display
		if ((this.props.authErr !== null) || (this.props.companyErr !== null) ||
			(this.props.profileErr !== null) || (this.props.chatErr !== null) ||
			(this.props.settingsErr !== null)) {
			debug('componentDidUpdate, show toast.');
			this.alertToast.show();
		}
	}

	render () {
		debug('render, called.');
		debug('render, authErr: ' + this.props.authErr + ', companyErr: ' +
			this.props.companyErr + ', profileErr: ' + this.props.profileErr +
			', chatErr: ' + this.props.chatErr + ', settingsErr: ' + this.props.settingsErr);

		this.err = '';
		this.message= '';

		if (this.props.authErr !== null) {this.err = this.props.authErr;}
		if (this.props.companyErr !== null) {this.err = this.props.companyErr;}
		if (this.props.profileErr !== null) {this.err = this.props.profileErr;}
		if (this.props.chatErr !== null) {this.err = this.props.chatErr;}
		if (this.props.settingsErr !== null) {this.err = this.props.settingsErr;}

		return <>
			<ErrorBoundary><MainMenu/></ErrorBoundary>
			<div className="saicvc-content">
				<ErrorBoundary>{this.props.children}</ErrorBoundary>
			</div>
			<ErrorBoundary><Footer/></ErrorBoundary>
			<ErrorBoundary><AlertToast message={this.err}/></ErrorBoundary>
			<ErrorBoundary><MessageToast message={this.message}/></ErrorBoundary>
		</>;
	}
}

MainView.propTypes = {
	authErr: 		PropTypes.string,
	profileErr:		PropTypes.string,
	companyErr:		PropTypes.string,
	chatErr:		PropTypes.string,
	settingsErr:	PropTypes.string,
	getUser:		PropTypes.func
};

export default MainView;
