/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import * as index from '../index';
import * as actions from './action_types';
import * as requests from '../requests';

export const createCompany = (name, logo, description, url) => ({ type: actions.CREATE_COMPANY, promise: requests.createCompany(name, logo, description, url), meta: {onSuccess: () => index.store.dispatch(getCompanies())}});
export const getCompanies = () => ({ type: actions.GET_COMPANIES, promise: requests.getCompanies() });
export const getCompany = (id) => ({ type: actions.GET_COMPANY, promise: requests.getCompany(id) });
export const updateCompany = (id, name, logo, description, url) => ({ type: actions.UPDATE_COMPANY, promise: requests.updateCompany(id, name, logo, description, url), meta: {onSuccess: () => index.store.dispatch(getCompany(id))}});
export const deleteCompany = (id) => ({ type: actions.DELETE_COMPANY, promise: requests.deleteCompany(id), meta: {onSuccess: () => index.store.dispatch(getCompanies())}});
export const addDoc = (id, doc) => ({ type: actions.ADD_DOC, promise: requests.addDoc(id, doc)});
export const deleteDoc = (id, doc) => ({ type: actions.DELETE_DOC, promise: requests.deleteDoc(id, doc)});
export const downloadWeb = (id, givenName, email) => ({ type: actions.DOWNLOAD_WEB, promise: requests.downloadWeb(id, givenName, email)});
export const storeData = (id, givenName, email) => ({ type: actions.STORE_DATA, promise: requests.storeData(id, givenName, email)});
