/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../shared/ErrorBoundary';
import { showMessage } from '../../shared/MessageToast';
import ChatViewer from '../../shared/ChatViewer';
import moment from 'moment';
import Debug from 'debug';

let debug = Debug('ChatTabView');


class ChatTabView extends Component {

    handleRead() {
        debug('handleRead, called.');
        var chatList = document.getElementById('chat-list');

		if (chatList.selectedIndex != -1) {
			this.props.getChat(chatList.options[chatList.selectedIndex].value);
		} else {
			showMessage('You must select a conversation to read.');
		}
    }

    handleDelete() {
        debug('handleDelete, called.');
        var chatList = document.getElementById('chat-list');

		if (chatList.selectedIndex != -1) {
			this.props.deleteChat(chatList.options[chatList.selectedIndex].value, this.props.company._id);
		} else {
			showMessage('You must select a converstion to delete.');
		}
    }

	renderChats() {
		debug('renderChats, called.');
		debug('renderChats: ' + JSON.stringify(this.props.chats));
		return this.props.chats.map(chat => <option value={chat._id} key={chat._id}>{moment(chat.time).format('DD-MMM-YYYY HH:MM')}</option>);
    }


  	render () {
    	debug('render, called.');
        if (this.props.isworking) {
            return null;
        } else {
    		return 	<div className="row h-100 align-items-start">
                <div className="col-md-9 h-100">
                    <h5>Previous Q&A</h5>
                    <ErrorBoundary><ChatViewer chat={this.props.chat}/></ErrorBoundary>
                </div>
				<div className="col-md-3">
	                <h5>Previous Chats</h5>
					<select id="chat-list" className="form-select" size="5" aria-label="chat list">
	                    <ErrorBoundary>{ this.renderChats() }</ErrorBoundary>
	                </select>
					<div className="row list-buttons">
                        <div className="col">
                            <button type="button" className="btn btn-outline-primary" onClick={ this.handleRead.bind(this) }>Read</button>
                        </div>
	                    <div className="col">
	                		<button type="button" className="btn btn-outline-danger" onClick={ this.handleDelete.bind(this) }>Delete</button>
						</div>
	                </div>
				</div>
            </div>;
        }
	}
};

ChatTabView.propTypes = {
    company:        PropTypes.object,
    isworking:      PropTypes.bool,
	chats:			PropTypes.array,
	chat:			PropTypes.object,
	getChat:		PropTypes.func,
    deleteChat:     PropTypes.func
};

export default ChatTabView;
