/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import Debug from 'debug';
import Main from '../Main';
import ChatTab from './tabs/ChatTab';
import DetailTab from './tabs/DetailTab';
import ErrorBoundary from '../shared/ErrorBoundary';
import { ADMIN } from '../shared/constants';

let debug = Debug('CompanyView');

class CompanyView extends Component {


	render () {
		debug('render, called.');

		return 	<Main>
            <div className="container-fluid main-bar">
                <div className="row align-items-center">
                    <div className="col">
                        <h4>Company</h4>
                    </div>
                </div>
                <hr className="my-3"/>
            </div>
            <div className="container-fluid h-100">
    			<nav>
    				<div className="nav nav-tabs" id="nav-tab" role="tablist">
						<button className="nav-link active" id="nav-detail-tab" data-bs-toggle="tab" data-bs-target="#nav-detail" type="button" role="tab" aria-controls="nav-detail" aria-selected="false">Details</button>
    					<button className="nav-link" id="nav-chat-tab" data-bs-toggle="tab" data-bs-target="#nav-chat" type="button" role="tab" aria-controls="nav-chat" aria-selected="true">Chats</button>
    				</div>
    			</nav>
    			<div className="tab-content h-100" id="nav-tabContent">

					<div className="tab-pane fade  h-100 show active" id="nav-detail" role="tabpanel" aria-labelledby="nav-detail-tab" tabIndex="1">
						<div className="container h-100 ">
							<ErrorBoundary><DetailTab/></ErrorBoundary>
						</div>
					</div>

    				<div className="tab-pane fade h-100" id="nav-chat" role="tabpanel" aria-labelledby="nav-chat-tab" tabIndex="0">
    					<div className="container h-100 ">
    						<ErrorBoundary><ChatTab/></ErrorBoundary>
    					</div>
    				</div>


                </div>
			</div>
		</Main>;

	}
};



CompanyView.propTypes = {
	user:			PropTypes.object
};


export default CompanyView;
