/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import * as index from '../index';
import * as actions from './action_types';
import * as requests from '../requests';

export const getProfiles = () => ({ type: actions.GET_PROFILES, promise: requests.getProfiles() });
export const getProfile = (id) => ({ type: actions.GET_PROFILE, promise: requests.getProfile(id) });
export const updateRole = (id, role) => ({ type: actions.UPDATE_ROLE, promise: requests.updateRole(id, role), meta: {onSuccess: () => index.store.dispatch(getProfiles())}});
export const deleteProfile = (id) => ({ type: actions.DELETE_PROFILE, promise: requests.deleteProfile(id), meta: {onSuccess: () => index.store.dispatch(getProfiles())}});
