/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import fetch from 'cross-fetch';
import { coreInit, fetchOk } from './request_utils';
import Debug from 'debug';

let debug = Debug('chat_requests');

export const getChats = (companyId) => {
	debug('getChats, called.');

	let init = {
		...coreInit,
		method:			'GET'
	};

	debug('getChats, init is: ' + JSON.stringify(init));
	return fetchOk('/chats/' + companyId, init)
		.then((response) => {debug('getChats, response ok.');return response;})
		.catch((error) => {debug('getChats, fetch, caught err.'); throw error;});
};

export const getChat = (id) => {
	debug('getChat, called.');

	let init = {
		...coreInit,
		method:			'GET'
	};

	debug('getChat, init is: ' + JSON.stringify(init));
	return fetchOk('/chat/' + id, init)
		.then((response) => {debug('getChat, response ok.');return response;})
		.catch((error) => {debug('getChat, fetch, caught err.'); throw error;});
};

export const startChat = (companyId) => {
	debug('startChat, called.');

	let init = {
		...coreInit,
		method:			'POST',
		body:			JSON.stringify({companyId: companyId})
	};

	debug('startChat, init is: ' + JSON.stringify(init));
	return fetchOk('/chat', init)
		.then((response) => {debug('startChat, response ok.');return response;})
		.catch((error) => {debug('startChat, fetch, caught err.'); throw error;});

};

export const ask = (id, prompt) => {
	debug('ask, called.');

	let init = {
		...coreInit,
		method:			'POST',
		body:			JSON.stringify({prompt: prompt})
	};

	debug('ask, init is: ' + JSON.stringify(init));
	return fetchOk('/chat/' + id, init)
		.then((response) => {debug('ask, response ok.');return response;})
		.catch((error) => {debug('ask, fetch, caught err.'); throw error;});

};

export const endChat = (id) => {
	debug('endChat, called.');


	let init = {
		...coreInit,
		method:			'PUT'
	};

	debug('endChat, init is: ' + JSON.stringify(init));
	return fetchOk('/chat/' + id, init)
		.then((response) => {debug('endChat, response ok.');return response;})
		.catch((error) => {debug('endChat, fetch, caught err.'); throw error;});

};

export const deleteChat = (id) => {
	debug('deleteChat, called.');


	let init = {
		...coreInit,
		method:			'DELETE'
	};

	debug('deleteChat, init is: ' + JSON.stringify(init));
	return fetchOk('/chat/' + id, init)
		.then((response) => {debug('deleteChat, response ok.');return response;})
		.catch((error) => {debug('deleteChat, fetch, caught err.'); throw error;});

};
