/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import HomeView from './HomeView';
import * as actions from '../../actions';
import Debug from 'debug';

let debug = Debug('Home');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		isworking:	state.company.isworking,
		companies:	state.company.companies
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		getCompanies: () => {dispatch(actions.getCompanies());},
		getCompany: (id) => {dispatch(actions.getCompany(id));},
	};
};

const Home = connect(mapStateToProps, mapDispatchToProps)(HomeView);

export default Home;
