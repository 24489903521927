/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import {MAX_FILE_SIZE} from 'constants';
import Debug from 'debug';

let debug = Debug('shared-functions');

export function currencyDisplay(value) {
	debug('currencyDisplay, called.');
	let dollarUSLocale = Intl.NumberFormat('en-US', { minimumFractionDigits: 2,  maximumFractionDigits: 2});
	return dollarUSLocale.format(value);
};

export function sleep(ms) {
	debug('sleep, called');
	return new Promise((resolve) => {
		setTimeout(resolve, ms);
	});
}

export function getLogoURL(logoPath) {
    debug('getLogoURL, called.');
    if (logoPath === '') {
        return 'images/placeholder.png';
    } else {
        return window.location.protocol +'//' + window.location.host + '/' + logoPath;
    }
}

export function getFileURL(id, filename) {
	debug('getFileURL, called.');
	return window.location.protocol + '//' + window.location.host + '/files/docs/' + id + '/' + filename;
}


export function sizeOk(file) {
    debug('sizeOk, called.');
    if (file) {
        debug('sizeOk, size: ' + file.size);
        return (file.size <= MAX_FILE_SIZE);
    } else {
        return true;
    }
}
