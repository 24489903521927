/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import Debug from 'debug';
import Main from '../Main';
import SystemForm from './forms/SystemForm';
import UserForm from './forms/UserForm';
import ErrorBoundary from '../shared/ErrorBoundary';
import { ADMIN } from '../shared/constants';

let debug = Debug('SettingsView');

class SettingsView extends Component {

	renderUserButton() {
		debug('renderUserButton, called.');
		if (this.props.user.role === ADMIN) {
			return <button className="nav-link" id="nav-topic-tab" data-bs-toggle="tab" data-bs-target="#nav-user" type="button" role="tab" aria-controls="nav-user" aria-selected="false">Users</button>;
		} else {
			return null;
		}
	}


	renderUserTab() {
		debug('renderUserTab, called.');
		if (this.props.user.role === ADMIN) {
			return <div className="tab-pane fade" id="nav-user" role="tabpanel" aria-labelledby="nav-topic-tab" tabIndex="0">
				<div className="container">
					<div className="row align-items-start">
						<div className="col-md-3"></div>
						<div className="col-md-6">
							<ErrorBoundary><UserForm /></ErrorBoundary>
						</div>
						<div className="col-md-3"></div>
					</div>
				</div>
			</div>;
		} else {
			return null;
		}
	}

	render () {
		debug('render, called.');

		return 	<Main>
      <div className="container-fluid main-bar">
          <div className="row align-items-center">
      			<h4>Settings</h4>
      			<nav>
      				<div className="nav nav-tabs" id="nav-tab" role="tablist">
      					<button className="nav-link active" id="nav-system-tab" data-bs-toggle="tab" data-bs-target="#nav-system" type="button" role="tab" aria-controls="nav-system" aria-selected="true">System</button>
      					<ErrorBoundary>{this.renderUserButton()}</ErrorBoundary>
      				</div>
      			</nav>
      			<div className="tab-content" id="nav-tabContent">

      				<div className="tab-pane fade show active" id="nav-system" role="tabpanel" aria-labelledby="nav-system-tab" tabIndex="0">
      					<div className="container">
      						<div className="row align-items-start">
      							<div className="col-md-3"></div>
      							<div className="col-md-6">
      								<ErrorBoundary><SystemForm/></ErrorBoundary>
      							</div>
      							<div className="col-md-3"></div>
      						</div>
      					</div>
      				</div>

      				<ErrorBoundary>{this.renderUserTab()}</ErrorBoundary>

      			</div>
        </div>
      </div>
		</Main>;

	}
};



SettingsView.propTypes = {
	user:			PropTypes.object
};


export default SettingsView;
