/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import * as index from '../index';
import * as actions from './action_types';
import * as requests from '../requests';

export const getSettings = () => ({ type: actions.GET_SETTINGS, promise: requests.getSettings() });
export const getParam = (id) => ({ type: actions.GET_PARAM, promise: requests.getParam(id) });
export const addParam = (param) => ({ type: actions.ADD_PARAM, promise: requests.addParam(param)});
export const updateParam = (id, param) => ({ type: actions.UPDATE_PARAM, promise: requests.updateParam(id, param)});
export const deleteParam = (id) => ({ type: actions.DELETE_PARAM, promise: requests.deleteParam(id)});
export const updateDepth = (depth) => ({ type: actions.UPDATE_DEPTH, promise: requests.updateDepth(depth)});
