/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../shared/ErrorBoundary';
import ChatViewer from '../../shared/ChatViewer';
import { hidden } from '../../shared/constants';
import { sizeOk } from '../../shared/functions';
import Debug from 'debug';

let debug = Debug('ChatView');

export class ChatView extends Component {

	handleKeyPress(event) {
		if (event.key == "Enter") {
	        this.handleAsk(event);
    	}
	}
	handleClose(event) {
		event.preventDefault();
		debug('handleClose, called.');
        this.props.endChat(this.props.chat._id);
	}


	handleAsk(event) {
		event.preventDefault();
		debug('handleAsk, called.');
		debug('handleAsk, chat: ' + this.props.chat);
		this.props.ask(this.props.chat._id, document.querySelector('#prompt').value);

		document.querySelector('#prompt').value = '';
	}


	render () {
		debug('render, called.');
		return <div className="modal modal-xl fade" id="chat-modal" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="feed-title" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered chat-dialog">
				<div className="modal-content chat-content">
					<div className="modal-header">
						<h5 className="modal-title" id="company-title">Q&A Dialog</h5>
					</div>
					<div className="modal-body chat-body">
						<ErrorBoundary><ChatViewer chat={this.props.chat}/></ErrorBoundary>
    					<div className="input-group mb-3" id="chat-input">
    						<input type="text" className="form-control" id="prompt" placeholder="What do you want to ask?" onKeyPress={(e) => this.handleKeyPress(e)} aria-label="question" />
    						<button className="btn btn-outline-secondary" type="button" onClick={(e) => this.handleAsk(e)}><i className="bi bi-chat-left-text" ></i></button>
    					</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-outline-primary" onClick={(e) => this.handleClose(e)} data-bs-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>;
	}
}


ChatView.propTypes = {
	isworking:		PropTypes.bool,
	chat:			PropTypes.object,
	ask:			PropTypes.func,
	endChat:		PropTypes.func
};


export default ChatView;
