/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import DeleteParamView from './DeleteParamView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('DeleteParam');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		allowedParam:			state.settings.allowedParam
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		deleteParam: (id) => {dispatch(actions.deleteParam(id));}
	};
};

const DeleteParam = connect(mapStateToProps, mapDispatchToProps)(DeleteParamView);

export default DeleteParam;
