/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component} from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthRoute from './shared/AuthRoute';
import Login from './login/Login';
import Home from './home/Home';
import Company from './company/Company';
import Profile from './profile/Profile';
import Settings from './settings/Settings';
import NotFound from './shared/NotFound';
import Debug from 'debug';

let debug = Debug('App');

export default class App extends Component{
	render () {
		debug('render, called.');

		return <BrowserRouter>
			<Routes>
				<Route exact path='/' element={(<AuthRoute><Home/></AuthRoute>)} />
				<Route path='/login' element={(<Login/>)} />
				<Route path='/home' element={(<AuthRoute><Home/></AuthRoute>)} />
                <Route path='/company' element={(<AuthRoute><Company/></AuthRoute>)} />
				<Route path='/profile' element={(<AuthRoute><Profile/></AuthRoute>)} />
				<Route path='/settings' element={(<AuthRoute><Settings/></AuthRoute>)} />
				<Route element={<NotFound />}/>
			</Routes>
		</BrowserRouter>;

	}
}
