/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../shared/ErrorBoundary';
import DetailPrep from './DetailPrep';
import DetailDocs from './DetailDocs';
import { sizeOk, getLogoURL } from '../../shared/functions';
import { showMessage } from '../../shared/MessageToast';
import Debug from 'debug';

let debug = Debug('DetailTabView');


class DetailTabView extends Component {

	shouldComponentUpdate() {
		debug('shouldComponentUpdate, called.');
		return this.props.isworking;
	}

    update() {
        debug('update, called.');

        if (sizeOk(document.querySelector('#logo').files[0])) {
    		this.props.updateCompany(
                this.props.company._id,
    			document.querySelector('#name').value,
                document.querySelector('#logo').files[0],
                document.querySelector('#description').value,
    			document.querySelector('#url').value
    		);
        } else {
            showMessage('The logo selected is too large.');
        }
    }

    getLogo() {
        if (this.props.company.logo === '') {
			return <div className="mb-3">
				<img id="detail-logo" src="./images/placeholder.png" alt="No logo" width="120px" height="120px"/>
			</div>;
		} else {
			return <div className="col-sm-9 form-group input-block">
				<img id="detail-logo" src={getLogoURL(this.props.company.logo)}  alt="Logo" width="120px" height="120px"/>
			</div>;
		}
    }

  	render () {
    	debug('render, called.');
        if (this.props.isworking) {
            return null;
        } else {
    		return 	<div className="row align-items-start">
                <div className="col-md-6">
                    <h5>1 - Check Details</h5>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input className="form-control" id="name" aria-label="company name" defaultValue={this.props.company.name} placeholder="Name of the company. (required)" required></input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="logo" className="form-label">Logo</label>
                        { this.getLogo() }
                        <div className="mb-3 form-group input-block">
                            <div className="custom-file">
                                <input id="logo" type="file" tabIndex={15} className="form-control custom-file-input" accept="image/png, image/jpeg, image/gif"/>
                            </div>
                            <p className="text-muted">Images should be png, gif, jpeg or jpeg files under 10Mb.</p>
                            <p className="text-info" id="too-large" hidden>The selected image is too large.</p>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="description" className="form-label">Description</label>
                        <textarea className="form-control" id="description" rows="3"  aria-label="description" defaultValue={this.props.company.description} placeholder="A brief description of the company and what they do."></textarea>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="url" className="form-label">Website</label>
                        <input type="url" className="form-control" id="url" aria-label="website" defaultValue={this.props.company.url} placeholder="The companies website. (required)" required></input>
                    </div>

                    <button type="button" className="btn btn-outline-primary" onClick={ this.update.bind(this) }>
                        Update Details
                    </button>
                </div>
                <ErrorBoundary><DetailDocs /></ErrorBoundary>
                <ErrorBoundary><DetailPrep /></ErrorBoundary>
            </div>;
        }
	}
};

DetailTabView.propTypes = {
	isworking:		PropTypes.bool,
    company:        PropTypes.object,
    updateCompany:  PropTypes.func
};

export default DetailTabView;
