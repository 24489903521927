/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getLogoURL } from '../../shared/functions';
import { Modal } from 'bootstrap';
import Debug from 'debug';

let debug = Debug('CompanyCardView');

class CompanyCardView extends Component {

    select(event) {
		debug('select, called.');
		this.props.getCompany(this.props.company._id);
        this.props.getChats(this.props.company._id);
	}

	isDisabled() {
		return !(this.props.company.downloaded && this.props.company.vectorized);
	}

	startChat() {
        debug('startChat, called.');
		this.props.startChat(this.props.company._id);

		const chatModal = new Modal('#chat-modal');
		chatModal.show();
    }

	render () {
		debug('render, called.');
		if (this.props.company) {
	  		return 	<div className="company-card col-sm-5 col-lg-3 mb-5" onClick={this.select.bind(this)}>
				<div className="card">
					<Link to='/company' state={{id: this.props.company._id}}><img src={getLogoURL(this.props.company.logo)} className="card-img-top" alt="Logo"/></Link>
					<div className="card-header">
						<Link to='/company' state={{id: this.props.company._id}}><h5 className="card-title">{this.props.company.name}</h5></Link>
					</div>
					<div className="card-body">
						<p className="card-text">{this.props.company.description}</p>
						<div className="d-grid gap-2">
  							<button className="btn btn-outline-primary" type="button" onClick={this.startChat.bind(this)} disabled={this.isDisabled()}>Start Q&A</button>
						</div>
					</div>
				</div>
			</div>;
		} else {
			return null;
		}
	}
};


CompanyCardView.propTypes = {
	getCompany:		PropTypes.func,
    getChats:       PropTypes.func,
	startChat:		PropTypes.func
};


export default CompanyCardView;
