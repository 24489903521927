/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels

 */
// Auth Action Types
export const AUTHENTICATE = 'AUTHENTICATE';
export const GET_USER = 'GET_USER';
export const LOGOUT = 'LOGOUT';

// Profile Action Types
export const GET_PROFILES = 'GET_PROFILES';
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const DELETE_PROFILE = 'DELETE_PROFILE';

// Company Action Types
export const CREATE_COMPANY = 'CREATE_COMPANY';
export const GET_COMPANIES = 'GET_COMPANIES';
export const GET_COMPANY = 'GET_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const ADD_DOC = 'ADD_DOC';
export const DELETE_DOC = 'DELETE_DOC';
export const DOWNLOAD_WEB = 'DOWNLOAD_WEB';
export const STORE_DATA = 'STORE_DATA';

// Chat Action Types
export const GET_CHATS = 'GET_CHATS';
export const GET_CHAT = 'GET_CHAT';
export const START_CHAT = 'START_CHAT';
export const ASK = 'ASK';
export const END_CHAT = 'END_CHAT';
export const DELETE_CHAT = 'DELETE_CHAT';

// Settings Action Types
export const GET_SETTINGS = 'GET_SETTINGS';
export const ADD_PARAM = 'ADD_PARAM';
export const GET_PARAM = 'GET_PARAM';
export const UPDATE_PARAM = 'UPDATE_PARAM';
export const DELETE_PARAM = 'DELETE_PARAM';
export const UPDATE_DEPTH = 'UPDATE_DEPTH';
