/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal } from 'bootstrap';
import ErrorBoundary from '../../shared/ErrorBoundary';
import UpdateUser from '../modals/UpdateUser';
import DeleteUser from '../modals/DeleteUser';
import { showMessage } from '../../shared/MessageToast';
import { Toast } from 'bootstrap';
import Debug from 'debug';

let debug = Debug('UserFormView');


class UserFormView extends Component {

	shouldComponentUpdate() {
		debug('shouldComponentUpdate, called.');
		return this.props.isworking;
	}


	componentDidMount() {
		debug('componentDidMount, called.');
		this.props.getProfiles();
	}


	buildUserList() {
		debug('buildUserList, called.');
		return this.props.profiles.map(profile => <option value={profile._id} key={profile._id}>{profile.givenName} {profile.familyName}</option>);
	}


	handleUpdate() {
		debug('handleUpdate, called.');
		var userList = document.getElementById('user-list');

		if (userList.selectedIndex != -1) {
			this.props.getProfile(userList.options[userList.selectedIndex].value);

			const userModal = new Modal('#update-user-modal');
			userModal.show();
		} else {
			showMessage('You must select a user to update.');
		}
	}


	handleDelete() {
		debug('handleDelete, called.');
		var userList = document.getElementById('user-list');

		if (userList.selectedIndex != -1) {
			this.props.getProfile(userList.options[userList.selectedIndex].value);

			const userModal = new Modal('#delete-user-modal');
			userModal.show();
		} else {
			showMessage('You must select a user to delete.');
		}

	}



  	render () {
    	debug('render, called.');

		return 	<form>
			<div>
				<div>
					<label htmlFor="user-list" className="form-label">User Details</label>
					<select className="form-select" id="user-list" size="5" aria-label="User selection" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-custom-classname="custom-tooltip" data-bs-title="A list of all the users.">
						<ErrorBoundary>{this.buildUserList()}</ErrorBoundary>
					</select>
				</div>
				<div>
					<div className="btn-group list-buttons" role="group">
						<button type="button" className="btn btn-outline-primary" onClick={this.handleUpdate.bind(this)}><i className="bi bi-pencil-square"></i></button>
						<button type="button" className="btn btn-outline-primary" onClick={this.handleDelete.bind(this)}><i className="bi bi-dash"></i></button>
					</div>
				</div>
				<UpdateUser/>
				<DeleteUser/>
			</div>
		</form>;
	}
};

UserFormView.propTypes = {
	isworking:		PropTypes.bool,
	getProfile: 		PropTypes.func,
	getProfiles: 		PropTypes.func,
	profiles:			PropTypes.array
};

export default UserFormView;
