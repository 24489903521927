/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../shared/ErrorBoundary';
import ReactStars from 'react-rating-stars-component';
import Debug from 'debug';
import moment from 'moment';

let debug = Debug('DeleteParamView');

export class DeleteParamView extends Component {

	handleDelete() {
		this.props.deleteParam(this.props.allowedParam._id);
	}


	renderBody() {
    let me = document.getElementById('delete-param-modal')
		if (this.props.allowedParam) {
			return <div className="modal-body">
				Ok to delete feed: {this.props.allowedParam.param}.
			</div>;
		} else {
			return <div className="modal-body">
			</div>;
		}
	}


	render () {
		debug('render, called.');
		return <div className="modal fade" id="delete-param-modal" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="param-title" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="param-title">Delete Parameter Dialog</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<ErrorBoundary>{this.renderBody()}</ErrorBoundary>
					<div className="modal-footer">
						<button type="button" className="btn btn-outline-danger" onClick={this.handleDelete.bind(this)} data-bs-dismiss="modal">Delete</button>
						<button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>;
	}
}


DeleteParamView.propTypes = {
	allowedParam: 	PropTypes.object,
	deleteParam:		PropTypes.func
};


export default DeleteParamView;
