/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../shared/ErrorBoundary';
import { showMessage } from '../../shared/MessageToast';
import { ADMIN, MAX_FILE_SIZE } from '../../shared/constants';
import { getFileURL } from '../../shared/functions';
import Debug from 'debug';

let debug = Debug('DetailDocsView');


class DetailDocsView extends Component {

	shouldComponentUpdate() {
		debug('shouldComponentUpdate, called.');
		return this.props.isworking;
	}

	handleAddDoc(event) {
    	debug('handleAddDoc, called.');
    	debug(document.querySelector('#add-file').files[0]);

		if (document.querySelector('#add-file').files[0].size > MAX_FILE_SIZE) {
			showMessage('The selected file is too large.');
		} else {
			this.props.addDoc(this.props.company._id, document.querySelector('#add-file').files[0]);
		}
	}

	handleDownloadDoc() {
		debug('handleDownloadDoc, called.');
        var docList = document.getElementById('doc-list');

		if (docList.selectedIndex != -1) {
			const url = getFileURL(this.props.company._id, docList.options[docList.selectedIndex].value);
			fetch(url)
  			.then(res => {
				if (res.status == 200) {
					const a = document.createElement("a");
		  			a.style.display = "none";
		  			document.body.appendChild(a);

		  			// Set the HREF to a Blob representation of the data to be downloaded
		  			a.href = url; // https://davidwalsh.name/javascript-download

		  			// Use download attribute to set set desired file name
		  			a.setAttribute("download", docList.options[docList.selectedIndex].value);

		  			// Trigger the download by simulating click
		  			a.click();

		  			// Cleanup
					window.URL.revokeObjectURL(a.href);
		  	  		document.body.removeChild(a);
				}

			})
			.catch(err => {

			});
		} else {
			showMessage('You must select a document to download.');
		}
	}

    handleDeleteDoc() {
        debug('handleDeleteDoc, called.');
        var docList = document.getElementById('doc-list');

		if (docList.selectedIndex != -1) {
			this.props.deleteDoc(this.props.company._id, docList.options[docList.selectedIndex].value);
		} else {
			showMessage('You must select a document to delete.');
		}

    }

    handleDownloadWeb() {
        debug('handleDownloadWeb, called.');
        this.props.downloadWeb(this.props.company._id, this.props.user.givenName, this.props.user.email);
    	showMessage('You will be emailed on completion.');
    }

    renderDownloadButton() {
        debug('renderDownloadButton, called.');
        if (this.props.company.downloaded) {
            return <button type="button" className="btn btn-outline-secondary" onClick={ this.handleDownloadWeb.bind(this)}>
                Re-download&nbsp;
                <i className="bi bi-check-circle"></i>
            </button>;
        } else {
            return <button type="button" className="btn btn-outline-primary" onClick={ this.handleDownloadWeb.bind(this)}>
                Download&nbsp;
                <i className="bi bi-x-circle"></i>
            </button>;
        }
    }

    renderDocuments() {
		debug('renderDocuments, called.');
		return this.props.company.docs.map(doc => <option value={doc} key={doc}>{doc}</option>);
    }

    renderDeleteButton() {
     	debug('renderDeleteButton, called.');
		if (this.props.user.role === ADMIN) {
			return <div className="col">
				<button type="button" className="btn btn-outline-danger" onClick={ this.handleDeleteDoc.bind(this) }><i className="bi bi-dash-circle"></i> PDF</button>
			</div>;
		} else {
			return null;
		}

    }

  	render () {
    	debug('render, called.');
        if (this.props.isworking) {
            return null;
        } else {
    		return <div className="col-md-3">
                    <h5>2 - Get Information</h5>

					<h6>A - Download website</h6>
                    <ErrorBoundary>{ this.renderDownloadButton() }</ErrorBoundary>
                    <p className="small">This will download the companies website and any PDFs on that site.</p>
                    <p className="small">This can take a while so you will be emailed when it is complete.</p>

                    <h6>B - Gather PDFs</h6>
                    <select className="form-select" id="doc-list" size="10" aria-label="document list">
                        <ErrorBoundary>{ this.renderDocuments() }</ErrorBoundary>
                    </select>
					<p className="small">Max file size is 20Mb.</p>
                    <div className="row text-center list-buttons">
                        <div className="col">
	                        <label className="btn btn-outline-primary">
	                            <i className="bi bi-plus-circle"></i> PDF <input id="add-file" type="file" accept="application/pdf" onChange={ this.handleAddDoc.bind(this) } hidden />
	                        </label>
                        </div>
						<div className="col">
							<button type="button" className="btn btn-outline-secondary" onClick={ this.handleDownloadDoc.bind(this) }><i className="bi bi-arrow-down-circle"></i> PDF</button>
						</div>
						<ErrorBoundary>{ this.renderDeleteButton()}</ErrorBoundary>
                    </div>
                </div>;
        }
	}
};

DetailDocsView.propTypes = {
	isworking:		PropTypes.bool,
    company:    	PropTypes.object,
	user:			PropTypes.object,
    addDoc:     	PropTypes.func,
    deleteDoc:  	PropTypes.func,
    downloadWeb:    PropTypes.func,

};

export default DetailDocsView;
