/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import { handle } from 'redux-pack';
import * as actions from '../actions';
import Debug from 'debug';

let debug = Debug('company_reducer');

const INITIAL_STATE = {
	isworking: false,
	companies: [],
	company: null,
	err: null
};

export default function reducer(state = INITIAL_STATE, action) {
	debug('reducer is called');
	const { type, payload } = action;

	debug('action : ' + action.type);

	switch (action.type) {

	case actions.CREATE_COMPANY: {
		debug('CREATE_COMPANY is called');
		debug('payload : ' + JSON.stringify(payload));
		return handle(state, action, {
			start: prevState => ({...prevState, isworking: true, company: null, err: null}),
			finish: prevState => ({ ...prevState, isworking: false }),
			failure: prevState => ({ ...prevState, err: payload.message }),
			success: prevState => ({ ...prevState, company: payload.company })
		});
	}
	case actions.GET_COMPANIES: {
		debug('GET_COMPANIES is called');
		debug('payload : ' + JSON.stringify(payload));
		return handle(state, action, {
			start: prevState => ({...prevState, isworking: true, companies: [], err: null}),
			finish: prevState => ({ ...prevState, isworking: false }),
			failure: prevState => ({ ...prevState, err: payload.message }),
			success: prevState => ({ ...prevState, companies: payload.companies })
		});
	}
	case actions.GET_COMPANY: {
		debug('GET_COMPANY is called');
		debug('payload : ' + JSON.stringify(payload));
		return handle(state, action, {
			start: prevState => ({...prevState, isworking: true, company: null, err: null}),
			finish: prevState => ({ ...prevState, isworking: false }),
			failure: prevState => ({ ...prevState, err: payload.message }),
			success: prevState => ({ ...prevState, company: payload.company })
		});
	}
	case actions.UPDATE_COMPANY: {
		debug('UPDATE_COMPANY is called');
		debug('payload : ' + JSON.stringify(payload));
		return handle(state, action, {
			start: prevState => ({...prevState, isworking: true, err: null}),
			finish: prevState => ({ ...prevState, isworking: false }),
			failure: prevState => ({ ...prevState, err: payload.message }),
			success: prevState => ({ ...prevState, company: payload.company })
		});
	}
	case actions.DELETE_COMPANY: {
		debug('DELETE_COMPANY is called');
		debug('payload : ' + JSON.stringify(payload));
		return handle(state, action, {
			start: prevState => ({...prevState, isworking: true, err: null}),
			finish: prevState => ({ ...prevState, isworking: false }),
			failure: prevState => ({ ...prevState, err: payload.message }),
			success: prevState => ({ ...prevState, company: payload.company })
		});
	}
    case actions.ADD_DOC: {
		debug('ADD_DOC is called');
		debug('payload : ' + JSON.stringify(payload));
		return handle(state, action, {
			start: prevState => ({...prevState, isworking: true, err: null}),
			finish: prevState => ({ ...prevState, isworking: false }),
			failure: prevState => ({ ...prevState, err: payload.message }),
			success: prevState => ({ ...prevState, company: payload.company })
		});
	}
    case actions.DELETE_DOC: {
		debug('DELETE_DOC is called');
		debug('payload : ' + JSON.stringify(payload));
		return handle(state, action, {
			start: prevState => ({...prevState, isworking: true, err: null}),
			finish: prevState => ({ ...prevState, isworking: false }),
			failure: prevState => ({ ...prevState, err: payload.message }),
			success: prevState => ({ ...prevState, company: payload.company })
		});
	}
    case actions.DOWNLOAD_WEB: {
		debug('DOWNLOAD_WEB is called');
		debug('payload : ' + JSON.stringify(payload));
		return handle(state, action, {
			start: prevState => ({...prevState, isworking: true, err: null}),
			finish: prevState => ({ ...prevState, isworking: false }),
			failure: prevState => ({ ...prevState, err: payload.message }),
			success: prevState => ({ ...prevState, company: payload.company })
		});
	}
    case actions.STORE_DATA: {
		debug('STORE_DATA is called');
		debug('payload : ' + JSON.stringify(payload));
		return handle(state, action, {
			start: prevState => ({...prevState, isworking: true, err: null}),
			finish: prevState => ({ ...prevState, isworking: false }),
			failure: prevState => ({ ...prevState, err: payload.message }),
			success: prevState => ({ ...prevState, company: payload.company })
		});
	}
	default:
		return state;
	}
}
