/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import ChatTabView from './ChatTabView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('ChatTab');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
        company:    state.company.company,
        isworking:   state.chat.isworking,
		chats:		 state.chat.chats,
		chat:		 state.chat.chat
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		getChat: (id) => {dispatch(actions.getChat(id));},
        deleteChat: (id, companyId) => {dispatch(actions.deleteChat(id, companyId));}
	};
};

const ChatTab = connect(mapStateToProps, mapDispatchToProps)(ChatTabView);

export default ChatTab;
