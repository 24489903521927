/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import MainView from './MainView';
import * as actions from '../actions';
import Debug from 'debug';

let debug = Debug('Main');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		authErr: 		state.auth.err,
		companyErr: 	state.company.err,
		profileErr:		state.profile.err,
		chatErr:		state.chat.err,
		settingsErr:	state.settings.err
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		getUser: () => {dispatch(actions.getUser());}
	};
};



const Main = connect(mapStateToProps, mapDispatchToProps)(MainView);

export default Main;
