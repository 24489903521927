/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component }  from 'react';
import Debug from 'debug';

let debug = Debug('Footer');

export default class Footer extends Component {

	render () {
		debug('render, called.');

		return 	<footer className="footer main-footer fixed-bottom bg-light">
			<div className="footer-content text-muted">&copy; 2023 SAIC USA</div>
		</footer>;

	}
};
