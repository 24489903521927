/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import SystemFormView from './SystemFormView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('SystemForm');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		isworking:	state.settings.isworking,
		settings:	state.settings.settings
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		getSettings: () => {dispatch(actions.getSettings());},
		getParam: (id) => {dispatch(actions.getParam(id));},
		updateDepth: (depth) => {dispatch(actions.updateDepth(depth));}
	};
};

const SystemForm = connect(mapStateToProps, mapDispatchToProps)(SystemFormView);

export default SystemForm;
