/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'bootstrap';
import ErrorBoundary from '../../shared/ErrorBoundary';
import { showMessage } from '../../shared/MessageToast';
import Debug from 'debug';

let debug = Debug('DetailPrepView');


class DetailPrepView extends Component {

	shouldComponentUpdate() {
		debug('shouldComponentUpdate, called.');
		return this.props.isworking;
	}



    handleRead() {
        debug('handleRead, called.');
        this.props.storeData(this.props.company._id, this.props.user.givenName, this.props.user.email);

        showMessage('You will be emailed on completion.');
    }



    getLoadBtn() {
        debug('getLoadBtn, called.');
        if (this.props.company.downloaded) {
            if (this.props.company.vectorized) {
                return <button type="button" className="btn btn-outline-secondary" onClick={ this.handleRead.bind(this)}>
                    Reload Docs &nbsp;
                    <i className="bi bi-check-circle"></i>
                </button>;
            } else {
                return <button type="button" className="btn btn-outline-primary" onClick={ this.handleRead.bind(this)}>
                    Load Docs&nbsp;
                    <i className="bi bi-x-circle"></i>
                </button>;
            }

        } else {
            return <button type="button" className="btn btn-outline-primary" disabled>
                Load Docs&nbsp;
                <i className="bi bi-x-circle"></i>
            </button>;
        }
    }

  	render () {
    	debug('render, called.');
        if (this.props.isworking) {
            return null;
        } else {
    		return 	<div className="col-md-3">
                <h5>3 - Load to the AI</h5>
                <br />
                <ErrorBoundary>{ this.getLoadBtn()}</ErrorBoundary>
                <p className="small">This load the gathered data into the AIs own database ready for dicussion.</p>
                <p className="small">This can take a while so you will be emailed when it is complete.</p>
            </div>;
        }
	}
};

DetailPrepView.propTypes = {
	isworking:		PropTypes.bool,
    company:        PropTypes.object,
	user:			PropTypes.object,
    storeData:      PropTypes.func
};

export default DetailPrepView;
