/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import CompanyCardView from './CompanyCardView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('CompanyCard');

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		getCompany: (id) => {dispatch(actions.getCompany(id));},
        getChats: (companyId) => {dispatch(actions.getChats(companyId));},
		startChat: (id) => {dispatch(actions.startChat(id));}
	};
};

const CompanyCard = connect(null, mapDispatchToProps)(CompanyCardView);

export default CompanyCard;
