/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component }  from 'react';
import ErrorBoundary from '../shared/ErrorBoundary';
import Footer from '../Footer';
import Debug from 'debug';

let debug = Debug('Login');

export default class Login extends Component {

	render () {
		debug('render, called.');

		return <>
			<div className="saicic-content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-4"></div>
						<div className="col-md-4">
							<div className="login-brand">
								<div className="d-flex justify-content-center">
									<span className="login-logo">
										<img alt="Logo" src="images/saic.png" height="40" width="40"/>
									</span>
									<span className="login-main-title">
										<h1> VC Pipeline Tool</h1>
									</span>
								</div>
							</div>
							<div className="panel panel-default login-panel">
								<div className="d-flex justify-content-center">
									<h2 className="panel-title login-title">Login</h2>
								</div>
								<div className="panel-body">
									<div className="d-grid gap-2">
										<a href="/auth/saic" className="btn btn-primary" type="button">SAIC Login</a>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
			<ErrorBoundary><Footer/></ErrorBoundary>
		</>;

	}
};
