/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import AddParamView from './AddParamView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('AddParam');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		settings:		state.settings.settings
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		addParam: (param) => {dispatch(actions.addParam(param));}
	};
};

const AddParam = connect(mapStateToProps, mapDispatchToProps)(AddParamView);

export default AddParam;
