/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../shared/ErrorBoundary';
import Debug from 'debug';

let debug = Debug('UpdateParamView');

export class UpdateParamView extends Component {

	handleUpdate() {
		debug('handleUpdate, called.');

		if (this.props.allowedParam) {
	    	this.props.updateParam(
				this.props.allowedParam._id,
				document.querySelector('#update-name').value
			);
		}
	}


	renderBody() {
		debug('renderBody, called.');
		if (this.props.allowedParam) {
			return <div className="modal-body">
				<div className="mb-3">
					<label htmlFor="update-name" className="form-label">Parameter name</label>
					<input className="form-control" id="update-name" defaultValue={this.props.allowedParam.param} aria-label="Parameter name" placeholder="The parameter name without & or =." required></input>
				</div>
			</div>;
		} else {
			return <div className="modal-body">
			</div>;
		}
	};


	render () {
		debug('render, called.');

		return <div className="modal fade" id="update-param-modal" tabIndex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="param-title" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="param-title">Update Param Dialog</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<ErrorBoundary>{this.renderBody()}</ErrorBoundary>
					<div className="modal-footer">
						<button type="button" className="btn btn-outline-primary" onClick={this.handleUpdate.bind(this)} data-bs-dismiss="modal">Update</button>
						<button type="button" className="btn btn-primary" data-bs-dismiss="modal">Close</button>
					</div>
				</div>
			</div>
		</div>;
	}
}


UpdateParamView.propTypes = {
	allowedParam:			PropTypes.object,
	updateParam:		PropTypes.func
};


export default UpdateParamView;
