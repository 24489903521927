/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import fetch from 'cross-fetch';
import { coreInit, fetchOk } from './request_utils';
import Debug from 'debug';

let debug = Debug('profile_requests');


export const getProfiles = () => {
	debug('getProfiles, called.');

	let init = {
		...coreInit,
		method:			'GET'
	};

	debug('getProfiles, init is: ' + JSON.stringify(init));
	return fetchOk('/profiles', init)
		.then((response) => {debug('getProfiles, response ok.');return response;})
		.catch((error) => {debug('getProfiles, fetch, caught err.'); throw error;});

};

export const getProfile = (id) => {
	debug('getProfile, called.');

	let init = {
		...coreInit,
		method:			'GET'
	};

	debug('getProfile, init is: ' + JSON.stringify(init));
	return fetchOk('/profile/' + id, init)
		.then((response) => {debug('getProfile, response ok.');return response;})
		.catch((error) => {debug('getProfile, fetch, caught err.'); throw error;});

};

export const updateRole = (id, role) => {
	debug('updateRole, called.');
	debug('updateRole, adding: ' + id + ', ' + role);
	const formData = new FormData();
	formData.append('role', role);

	let init = {
		credentials:	'same-origin',
		method:			'PUT',
		body:			formData
	};

	debug('updateRole, init is: ' + JSON.stringify(init));
	return fetchOk('/profile/role/' + id, init)
		.then((response) => {debug('updateRole, response ok.');return response;})
		.catch((error) => {debug('updateRole, fetch, caught err.'); throw error;});

};

export const deleteProfile = (id) => {
	debug('deleteProfile, called.');

	let init = {
		...coreInit,
		method:			'DELETE',
		body:			JSON.stringify({'id': id})
	};

	debug('deleteProfile, init is: ' + JSON.stringify(init));
	return fetchOk('/profile/' + id, init)
		.then((response) => {debug('deleteProfile, response ok.');return response;})
		.catch((error) => {debug('deleteProfile, fetch, caught err.'); throw error;});

};
