/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal } from 'bootstrap';
import ErrorBoundary from '../../shared/ErrorBoundary';
import AddParam from '../modals/AddParam';
import UpdateParam from '../modals/UpdateParam';
import DeleteParam from '../modals/DeleteParam';
import { showMessage } from '../../shared/MessageToast';
import { Toast } from 'bootstrap';
import Debug from 'debug';

let debug = Debug('SystemFormView');


class SystemFormView extends Component {

	componentDidMount() {
		debug('componentDidMount, called.');
		this.props.getSettings();
	}

  handleAdd() {
		debug('handleAdd, called.');
		const paramModal = new Modal('#add-param-modal');
		paramModal.show();
	}

  handleUpdate() {
		debug('handleUpdate, called.');
		var paramList = document.getElementById('param-list');

		if (paramList.selectedIndex != -1) {
			this.props.getParam(paramList.options[paramList.selectedIndex].value);

			const paramModal = new Modal('#update-param-modal');
			paramModal.show();
		} else {
			showMessage('You must select a parameter to update.');
		}
	}

  handleDelete() {
		debug('handleDelete, called.');
		var paramList = document.getElementById('param-list');

		if (paramList.selectedIndex != -1) {
			this.props.getParam(paramList.options[paramList.selectedIndex].value);

			const paramModal = new Modal('#delete-param-modal');
			paramModal.show();
		} else {
			showMessage('You must select a parameter to delete.');
		}
	}

  handleDepth() {
		debug('handleDepth, called.');
		this.props.updateDepth(document.querySelector('#download-depth').value)
	}

	renderParamsList() {
		debug('renderParamsList, called.');
    return this.props.settings ? this.props.settings.allowedParams.map(param => <option value={param._id} key={param._id}>{param.param}</option>) : null;
	}

  renderDownloadDepth() {
    debug('renderDownloadDepth, called.');
    if (this.props.settings) {
      return <div>
        <label htmlFor="download-depth" className="form-label">Download depth</label>
          <input type="number" className="form-control" id="download-depth" min="1" max="10" value={ this.props.settings.downloadDepth } onChange={ this.handleDepth.bind(this) }></input>
      </div>;
    } else {
      <div>
        <label htmlFor="download-depth" className="form-label">Download depth</label>
          <input type="number" className="form-control" id="download-depth" min="1" max="10" disabled="true"></input>
      </div>
    }

  }



  	render () {
    	debug('render, called.');

  		return 	<form>
        <div>
          <label htmlFor="param-list" className="form-label">Accepted Params</label>
          <select className="form-select" id="param-list"size="5" aria-label="accepted params">
            <ErrorBoundary>{ this.renderParamsList() }</ErrorBoundary>
          </select>
        </div>
        <div>
          <div className="btn-group  list-buttons" role="group" aria-label="param-controls">
            <button type="button" className="btn btn-outline-primary" onClick={this.handleAdd.bind(this)}><i className="bi bi-plus-lg"></i></button>
            <button type="button" className="btn btn-outline-primary" onClick={this.handleUpdate.bind(this)}><i className="bi bi-pencil-square"></i></button>
            <button type="button" className="btn btn-outline-primary" onClick={this.handleDelete.bind(this)}><i className="bi bi-dash"></i></button>
          </div>
          <p className="small">All params are ignored unless they are on this list eg: https://www.example.com/?page_id=1 the whole '?page_id=1' will be ignored unless 'page_id' is listed above.</p>
        </div>
        <ErrorBoundary>{ this.renderDownloadDepth() }</ErrorBoundary>
        <p className="small">The depth refers to how many sections in the URL will be followed eg: https://www.example.com/1/2/3/4</p>
        <ErrorBoundary><AddParam /></ErrorBoundary>
        <ErrorBoundary><UpdateParam /></ErrorBoundary>
        <ErrorBoundary><DeleteParam /></ErrorBoundary>
      </form>;
  	}
};

SystemFormView.propTypes = {
	isworking:       PropTypes.bool,
	getSettings:     PropTypes.func,
	getParam:				 PropTypes.func,
	updateDepth:     PropTypes.func,
	settings:        PropTypes.object
};

export default SystemFormView;
