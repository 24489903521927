/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import UpdateParamView from './UpdateParamView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('UpdateParam');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		allowedParam:		state.settings.allowedParam
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		updateParam: (id, param) => {dispatch(actions.updateParam(id, param));}
	};
};

const UpdateParam = connect(mapStateToProps, mapDispatchToProps)(UpdateParamView);

export default UpdateParam;
