/**
 * Copyright 2022 Initiate Thinking (https://www.initiatethinking.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import ChatView from './ChatView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('Chat');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		isworking:		state.chat.isworking,
		chat:			state.chat.chat
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
		ask: (id, prompt) => {dispatch(actions.ask(id, prompt));},
		endChat: (id) => {dispatch(actions.endChat(id));}
	};
};

const Chat = connect(mapStateToProps, mapDispatchToProps)(ChatView);

export default Chat;
