/**
 * Copyright 2023 SAIC USA (https://www.saicic.com)
 * Author: Nigel Daniels
 */
import {connect} from 'react-redux';
import DetailPrepView from './DetailPrepView';
import * as actions from '../../../actions';
import Debug from 'debug';

let debug = Debug('DetailPrep');

const mapStateToProps = (state) => {
	debug('mapStateToProps: called');
	return {
		isworking:	state.company.isworking,
        company:    state.company.company,
		user:		state.auth.user
	};
};

const mapDispatchToProps = (dispatch) => {
	debug('mapDispatchToProps, called');
	return {
        storeData: (id, givenName, email) => {dispatch(actions.storeData(id, givenName, email));}
	};
};

const DetailPrep = connect(mapStateToProps, mapDispatchToProps)(DetailPrepView);

export default DetailPrep;
